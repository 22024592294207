import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axiosPlugin from './plugins/axios';

import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueCookies from "vue-cookies";
import Vuelidate from "vuelidate";

Vue.config.productionTip = false;

let protocol = `${window.location.protocol}//`;
let hostname = window.location.hostname;
let port = window.location.port ? `:${window.location.port}` : "";

Vue.prototype.$path = "/api/v2/shop/auth_partner";
Vue.prototype.$redirectUrl = `${protocol}${hostname}${port}/callback/`;

//---------test----------
Vue.prototype.$baseUrl = "https://platform-service-api-new.dosetech.co/api";
Vue.prototype.$hostShopee = "https://partner.test-stable.shopeemobile.com";
Vue.prototype.$partnerId = "850479";
Vue.prototype.$partnerKey = "056aa0cf2ff08f3bacd6f4cd27ed5b12ff167302caf031b93078505ce58b3b13";

//---------production----------
// Vue.prototype.$baseUrl = "https://prod-platform-service-api.dosetech.co/api";
// Vue.prototype.$hostShopee = "https://partner.shopeemobile.com";
// Vue.prototype.$partnerId = "2000473";
// Vue.prototype.$partnerKey = "8851a9f7d473c5dab027e6f5367c66d5d633f49b7835e95c6503cc5b65281126";

Vue.use(BootstrapVue);
Vue.use(VueCookies);
Vue.use(Vuelidate);
Vue.use(axiosPlugin);

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authentication)) {
    if (!localStorage.getItem('dt_access_token')) {
      next({
        path: "/login",
      })
    } else {
      next()
    }
  }
  else {
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");