import Vue from "vue";
import VueRouter from "vue-router";

const Login = () =>
    import ('@/views/Login.vue');
// const Register = () => import('@/views/Register.vue');
const LoginShopee = () =>
    import ('@/views/LoginShopee.vue');
const LoginMirakl = () =>
    import ('@/views/LoginMirakl.vue');
const status404 = () =>
    import ('@/views/Layouts/404.vue');

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter);

const routes = [{
        path: "/Login/:msg",
        name: "callback-msg",
        component: LoginShopee,
        meta: {
            authentication: true
        }
    },
    {
        path: "/Login",
        name: "login",
        component: Login
    },
    {
        path: "/LoginMirakl",
        name: "login mirakl",
        component: LoginMirakl
    },
    // {
    //   path: "/Register",
    //   name: "register",
    //   component: Register
    // },
    {
        path: "/",
        name: "index",
        component: LoginShopee,
        meta: {
            authentication: true
        }
    },
    {
        path: "/callback",
        name: "callback",
        component: LoginShopee,
        meta: {
            authentication: true
        }
    },
    {
        path: "/404",
        name: "/404",
        component: status404,
    },
    {
        path: "*",
        name: "status-404",
        component: status404,
    },
];

const router = new VueRouter({
    mode: "history",
    // base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
});

export default router;