// import axios from "axios";
import VueAxios from "vue-axios";
import axios from 'axios'
// const accessToken = localStorage.getItem('dt_access_token') || null

// API request
axios.interceptors.request.use(function (config) {
  // if (accessToken) {
  //   config.headers.Authorization = accessToken
  // }

  config.headers['Content-Type'] = 'application/json'

  return config
})

// API response
axios.interceptors.response.use(
  function (response) {
    return Promise.resolve(response.data)
  },
  function (error) {
    if (401 === error.response.status) {
      localStorage.removeItem('dt_access_token')
      // window.location = '/login'
      return Promise.reject(error.response.data)
    } else if (400 === error.response.status) {
      return Promise.reject(error.response.data)
    } else {
      return Promise.reject(error.response.data)
    }
  }
)

// Vue.prototype.$axios = axios
export default {
  install(Vue) {
    // axios.defaults.baseURL = "https://jsonplaceholder.typicode.com";
    Vue.use(VueAxios, axios);
  }
}